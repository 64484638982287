













































































































































.classDetail{
  .searchbox{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .itemLabel{
    margin: 0 20px;
  }
  .df{
    margin-left: 20px;
  }
}
